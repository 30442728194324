import config from "@/config"
import http from "@/utils/request"

export default {
    facilities:{
        list:{
            url: `${config.API_URL}/facilities/list`,
            name: "获取用户的个人信息",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
    },
    //系统参数
    param:{
        list:{
            url: `${config.API_URL}/config_param/list`,
            name: "获取系统参数信息列表",
            get: async function(){
                return await http.get(this.url);
            }
        },
        update:{
            url: `${config.API_URL}/config_param/save`,
            name: "更新/添加系统参数信息",
            post: async function(param){
                return await http.post(this.url,param);
            }
        },
    },
}