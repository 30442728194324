import config from "@/config"
import http from "@/utils/request"

export default {
    //商品类型参数
    route_type:{
        list:{
            url: `${config.API_URL}/route_type/list`,
            name: "获取攻略类型",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
        save:{
            url: `${config.API_URL}/route_type/save`,
            name: "保存攻略类型",
            post: async function(param){
                return await http.post(this.url,param);
            }
        },
        detail:{
            url: `${config.API_URL}/route_type/detail`,
            name: "获取攻略类型详情",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
    },
    //攻略参数
    route:{
        list:{
            url: `${config.API_URL}/route/list`,
            name: "获取攻略列表",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
        save:{
            url: `${config.API_URL}/route/save`,
            name: "保存攻略",
            post: async function(param){
                return await http.post(this.url,param);
            }
        },
        detail:{
            url: `${config.API_URL}/route/detail`,
            name: "获取攻略详情",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
		img_list:{
		    url: `${config.API_URL}/route_img/list`,
		    name: "获取攻略图片列表",
		    post: async function(param){
		        return await http.get(this.url,param);
		    }
		},
		img_save:{
		    url: `${config.API_URL}/route_img/save`,
		    name: "保存攻略图片",
		    post: async function(param){
		        return await http.get(this.url,param);
		    }
		},
		img_del:{
		    url: `${config.API_URL}/route_img/del`,
		    name: "删除攻略图片",
		    post: async function(param){
		        return await http.get(this.url,param);
		    }
		},
		img_displayorder:{
		    url: `${config.API_URL}/route_img/displayorder`,
		    name: "批量设置排序",
		    post: async function(param){
		        return await http.post(this.url,param);
		    }
		},
    },





}