import config from "@/config"
import http from "@/utils/request"
export  default {

    index:{
        url: `${config.API_URL}/logs/index`,
        name: "获取员工考勤的列表信息",
        get: async function(param){
            return await http.get(this.url,param);
        }
    },
}