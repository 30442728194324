import {
	createApp
} from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css'
import App from './App.vue'
import init from './init'
import router from './router'
import store from './store'
import i18n from './locales'



const app = createApp(App);
app.use(init);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(i18n)
app.mount('#app');

router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	const targetPath = router.history.pending.fullPath;
	if (isChunkLoadFailed) {
		router.replace(targetPath);
	}
});

const debounce = (fn, delay) => {
	let timer = null;
	return function() {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function() {
			fn.apply(context, args);
		}, delay);
	}
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
}