import config from "@/config"
import http from "@/utils/request"

//系统设置模块
export  default {
	/******************************************************基础工资管理*************************************************************/
	wages:{
		index:{
			url: `${config.API_URL}/wages/index`,
			name: "获取工资的列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
		create:{
			url: `${config.API_URL}/wages/create`,
			name: "员工工资生成入库",
			post: async function(data){
				return await http.get(this.url,data);
			}
		},
		export:{
			url: `${config.API_URL}/wages/export`,
			name: "员工工资导出",
			post: async function(data){
				return await http.post(this.url,data,{},'blob');
			}
		},
		index_by_staff:{
			url: `${config.API_URL}/wages_basic/soft_delete`,
			name: "根据员工提取该员工的所有工资信息",
			get: async function(data){
				return await http.get(this.url,data);
			}
		}
	},
	/******************************************************工资管理人员审核*************************************************************/
	wages_review:{
		index:{
			url: `${config.API_URL}/wages_review/index`,
			name: "获取工资管理人员审核列表",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
		save:{
			url: `${config.API_URL}/wages_review/setData`,
			name: "工资管理人员审核",
			post: async function(data){
				return await http.get(this.url,data);
			}
		},
		export:{
			url: `${config.API_URL}/wages/export`,
			name: "员工工资导出",
			post: async function(data){
				return await http.post(this.url,data,{},'blob');
			}
		},
		index_by_staff:{
			url: `${config.API_URL}/wages_basic/soft_delete`,
			name: "根据员工提取该员工的所有工资信息",
			get: async function(data){
				return await http.get(this.url,data);
			}
		}
	},	
	/******************************************************基础工资管理*************************************************************/
	wages_basic:{
	    index:{
	        url: `${config.API_URL}/wages_basic/index`,
	        name: "获取员工基础工资的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/wages_basic/setData`,
	        name: "保存员工基础工资",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
	    soft_delete:{
	        url: `${config.API_URL}/wages_basic/soft_delete`,
	        name: "删除员工基础工资",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    }
	},
    /******************************************************考勤管理*************************************************************/
    detail_check:{
		dashboard:{
			url: `${config.API_URL}/detail_check/dashboard`,
			name: "控制面板获取员工考勤记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
        index:{
            url: `${config.API_URL}/detail_check/index`,
            name: "获取员工考勤的列表信息",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },

        save:{
            url: `${config.API_URL}/detail_check/setData`,
            name: "保存员工考勤数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },
        soft_delete:{
            url: `${config.API_URL}/detail_check/soft_delete`,
            name: "删除员工考勤数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    },
	/******************************************************津贴管理*************************************************************/
	detail_allowance:{
	    index:{
	        url: `${config.API_URL}/detail_allowance/index`,
	        name: "获取员工津贴的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/detail_allowance/setData`,
	        name: "保存员工津贴数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
	    soft_delete:{
	        url: `${config.API_URL}/detail_allowance/soft_delete`,
	        name: "删除员工津贴数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    }
	},
	
	
	/*******************************************************员工罚款管理*************************************************************/
	detail_fine:{

		dashboard:{
			url: `${config.API_URL}/detail_fine/dashboard`,
			name: "控制面板获取员工罚款记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},

		index:{
	        url: `${config.API_URL}/detail_fine/index`,
	        name: "获取员工罚款的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/detail_fine/setData`,
	        name: "保存员工罚款数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },

		get_all_by_month:{
			url: `${config.API_URL}/detail_fine/getListWithOutPage`,
			name: "获取员工罚款数据列表 某一个月的",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},

		soft_delete:{
			url: `${config.API_URL}/detail_fine/soft_delete`,
			name: "删除员工罚款提成数据",
			post: async function(data){
				return await http.post(this.url,data);
			}
		}
	},
	
	
	/*******************************************************员工餐饮提成管理*************************************************************/
	detail_cook:{
		dashboard:{
			url: `${config.API_URL}/detail_cook/dashboard`,
			name: "控制面板获取员工做餐记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},

	    index:{
	        url: `${config.API_URL}/detail_cook/index`,
	        name: "获取员工餐饮提成的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
		getListWithOutPage:{
			url: `${config.API_URL}/detail_cook/getListWithOutPage`,
			name: "获取员工餐饮提成的列表信息-无分页",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
	    save:{
	        url: `${config.API_URL}/detail_cook/setData`,
	        name: "保存员工餐饮提成数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
		soft_delete:{
			url: `${config.API_URL}/detail_cook/soft_delete`,
			name: "删除员工餐饮提成数据",
			post: async function(data){
				return await http.post(this.url,data);
			}
		}
	},
	
	
	/*******************************************************员工间夜提成管理*************************************************************/
	detail_kpi:{
		dashboard:{
			url: `${config.API_URL}/detail_kpi/dashboard`,
			name: "控制面板获取员工几下记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
	    index:{
	        url: `${config.API_URL}/detail_kpi/index`,
	        name: "获取员工间夜提成的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/detail_kpi/setData`,
	        name: "保存员工间夜提成数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
		get_all_by_staff:{
			url: `${config.API_URL}/detail_kpi/getListByStaff`,
				name: "根据管家ID获取某月份的绩效明细",
			get: async function(data){
				return await http.get(this.url,data);
			}
		},
		soft_delete:{
			url: `${config.API_URL}/detail_kpi/soft_delete`,
			name: "删除员工间夜提成数据",
			post: async function(data){
				return await http.post(this.url,data);
			}
		},
	},
	
	
	/*******************************************************员工加班管理*************************************************************/
	detail_work:{
		dashboard:{
			url: `${config.API_URL}/detail_work/dashboard`,
			name: "控制面板获取员工加班记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},

	    index:{
	        url: `${config.API_URL}/detail_work/index`,
	        name: "获取员工加班的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
		getListWithOutPage:{
			url: `${config.API_URL}/detail_work/getListWithOutPage`,
			name: "获取某一个员工当前月份的加班信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
	    save:{
	        url: `${config.API_URL}/detail_work/setData`,
	        name: "保存员工加班数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
		soft_delete:{
			url: `${config.API_URL}/detail_work/soft_delete`,
			name: "删除员工加班数据",
			post: async function(data){
				return await http.post(this.url,data);
			}
		},
	},
	
	
	/*******************************************************员工福利管理*************************************************************/
	wages_welfare:{
		dashboard:{
			url: `${config.API_URL}/wages_welfare/dashboard`,
			name: "控制面板获取员工奖励记录列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},

	    index:{
	        url: `${config.API_URL}/wages_welfare/index`,
	        name: "获取员工福利补贴的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/wages_welfare/setData`,
	        name: "保存员工福利补贴数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },

		get_all_by_year:{
			url: `${config.API_URL}/wages_welfare/getListWithOutPage`,
			name: "获取公司奖励以年作为单位",
			get: async function(param){
				return await http.get(this.url,param);
			}
		}
	},
	/*******************************************************员工社保个税公积金管理*************************************************************/
	wages_withhold:{
	    index:{
	        url: `${config.API_URL}/wages_withhold/index`,
	        name: "获取员工社保个税公积金的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	    save:{
	        url: `${config.API_URL}/wages_withhold/setData`,
	        name: "保存员工社保个税公积金数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
		get_all_by_year:{
			url: `${config.API_URL}/wages_withhold/getListWithOutPage`,
			name: "获取员工社保个税公积金的列表信息",
			get: async function(param){
				return await http.get(this.url,param);
			}
		},
	},
	
	/*******************************************************员工工资预览管理*************************************************************/
	wages_preview:{
	    index:{
	        url: `${config.API_URL}/wages_preview/index`,
	        name: "获取员工工资预览的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
		create:{
		    url: `${config.API_URL}/wages_preview/create`,
		    name: "员工工资预览批量生成",
		    post: async function(param){
		        return await http.get(this.url,param);
		    }
		},
	},
}