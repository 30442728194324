import config from "@/config";
import tool from '@/utils/tool';

var menuIsCollapse = config.MENU_IS_COLLAPSE;
if(tool.data.get("USER_INFO")){
	menuIsCollapse = tool.data.get("USER_INFO").menuisopen ? true :false
}

export default {
	state: {
		//移动端布局
		ismobile: false,
		//布局
		layout: config.LAYOUT,
		//菜单是否折叠 toggle
		menuIsCollapse: menuIsCollapse,
		//多标签栏
		layoutTags: config.LAYOUT_TAGS,
		//主题
		theme: config.THEME,
	},
	mutations: {
		SET_ismobile(state, key){
			state.ismobile = key
		},
		SET_layout(state, key){
			state.layout = key
		},
		SET_theme(state, key){
			state.theme = key
		},
		TOGGLE_menuIsCollapse(state){
			state.menuIsCollapse = state.menuIsCollapse ? false : true
			var menuisopen = state.menuIsCollapse ? 1 : 0
			var USER_INFO = tool.data.get("USER_INFO");
			USER_INFO['menuisopen'] = menuisopen;
			tool.data.set("USER_INFO",USER_INFO);
		},
		TOGGLE_login_menuIsCollapse(state,status){
			state.menuIsCollapse = status
		},
		TOGGLE_layoutTags(state,key){
			state.layoutTags = key
		}
	}
}
