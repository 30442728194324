<template>
  <el-config-provider :locale="locale">
		<router-view></router-view>
	</el-config-provider>
</template>

<script>
	import colorTool from '@/utils/color'
	import tool from './utils/tool'

	export default {
		name: 'App',
		data() {
			return {
				config: {
					size: "default",
					zIndex: 2000,
					button: {
						autoInsertSpace: false
					}
				}
			}
		},
		computed: {
			locale() {
				return this.$i18n.messages[this.$i18n.locale].el
			},
		},
		created() {
			var nowDate = new Date();
			var date = {
				year:tool.timeFormat((Date.parse(nowDate)/1000),4),
				month:  tool.timeFormat((Date.parse(nowDate)/1000),3),
				prev_month:  tool.timeFormat((Date.parse(nowDate)/1000)-3600*24*(nowDate.getDate()),3),
				day:tool.timeFormat((Date.parse(nowDate)/1000),2),
				next_day:tool.timeFormat((Date.parse(nowDate)/1000)+3600*24,2)
			};

			tool.data.set('NOW_DATE',date);

			//设置主题颜色
			const app_color = this.$CONFIG.COLOR || this.$TOOL.data.get('APP_COLOR')
			if (app_color) {
				document.documentElement.style.setProperty('--el-color-primary', app_color);
				for (let i = 1; i <= 9; i++) {
					document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, colorTool.lighten(
						app_color, i / 10));
				}
				for (let i = 1; i <= 9; i++) {
					document.documentElement.style.setProperty(`--el-color-primary-dark-${i}`, colorTool.darken(app_color,
						i / 10));
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '@/style/style.scss';
</style>