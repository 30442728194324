import config from "@/config"
import http from "@/utils/request"

//系统设置模块
export  default {
    /*******************************************************员工设置*************************************************************/
    staff:{
        index:{
            url: `${config.API_URL}/staff/index`,
            name: "获取员工的列表信息",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },
	    all:{
	        url: `${config.API_URL}/staff/all`,
	        name: "获取全部员工列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },	
        save:{
            url: `${config.API_URL}/staff/setData`,
            name: "保存员工数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },
        export:{
            url: `${config.API_URL}/staff/export`,
            name: "批量导入员工数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },
        delete_batch:{
            url: `${config.API_URL}/staff/delete`,
            name: "批量删除员工数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    },
	/*******************************************************员工基本设置*************************************************************/
	staff_basic:{
	    index:{
	        url: `${config.API_URL}/staff_basic/index`,
	        name: "获取员工基本工资的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	    save:{
	        url: `${config.API_URL}/staff_basic/setData`,
	        name: "保存员工基本工资数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
	
	    detail:{
	        url: `${config.API_URL}/staff_basic/detail`,
	        name: "获取员工基本工资的详情信息",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    }
	},
}