import config from "@/config"
import http from "@/utils/request"

//系统设置模块
export  default {
    /***********************************************管理员进行自己的个性化设置************************************************************/
    setting:{
        url: `${config.API_URL}/admin/save`,
        name: "获取用户的个人信息",
        post: async function(data){
            return await http.post(this.url,data);
        }
    },

    /************************************************************系统设置下面管理员模块**********************************************/
    admin:{
        index: {
            url: `${config.API_URL}/admin/index`,
            name: "获取管理员用户列表",
            get: async function(data={}){
                return await http.get(this.url, data);
            }
        },
        detail: {
            url: `${config.API_URL}/admin/detail`,
            name: "获取用户的个人信息",
            get: async function(data){
                return await http.get(this.url,data);
            }
        },
        update:{
            url: `${config.API_URL}/admin/save`,
            name: "获取用户的个人信息",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },
        delete_batch:{
            url: `${config.API_URL}/admin/delete`,
            name: "获取删除管理员信息",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    },

    /*******************************************************部门设置*************************************************************/
    dept:{
        index:{
            url: `${config.API_URL}/dept/index`,
            name: "获取获取部门的列表信息",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },

        save:{
            url: `${config.API_URL}/dept/setData`,
            name: "保存部门数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },

        delete_batch:{
            url: `${config.API_URL}/dept/delete`,
            name: "批量删除部门数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    },
	
	
	/*******************************************************岗位设置*************************************************************/
	posts:{
	    index:{
	        url: `${config.API_URL}/posts/index`,
	        name: "获取获取岗位的列表信息",
	        get: async function(param){
	            return await http.get(this.url,param);
	        }
	    },
	
	    save:{
	        url: `${config.API_URL}/posts/setData`,
	        name: "保存岗位数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    },
	
	    delete_batch:{
	        url: `${config.API_URL}/posts/delete`,
	        name: "批量删除岗位数据",
	        post: async function(data){
	            return await http.post(this.url,data);
	        }
	    }
	},

    /************************************************************系统设置下面管理员模块**********************************************/
    role:{
        index:{
            url: `${config.API_URL}/role/index`,
            name: "获取菜单信息列表",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },

        detail: {
            url: `${config.API_URL}/role/detail`,
            name: "获取角色详细信息",
            get: async function(){
                return await http.get(this.url);
            }
        },
        update:{
            url: `${config.API_URL}/role/save`,
            name: "保存角色信息",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },

        delete_batch:{
            url: `${config.API_URL}/role/delete`,
            name: "批量删除角色数据",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    },
    /************************************************************系统设置下面菜单模块**********************************************/
    menu:{
        index:{
            url: `${config.API_URL}/menu/index`,
            name: "获取菜单信息列表",
            get: async function(param){
                return await http.get(this.url,param);
            }
        },

        orderChange:{
            url: `${config.API_URL}/menu/orderChange`,
            name: "菜单拖拽保存",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },

        update:{
            url: `${config.API_URL}/menu/setData`,
            name: "更新菜单信息",
            post: async function(data){
                return await http.post(this.url,data);
            }
        },
        delete:{
            url: `${config.API_URL}/menu/delete`,
            name: "菜单软删除",
            post: async function(data){
                return await http.post(this.url,data);
            }
        }
    }
}