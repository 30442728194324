import config from "@/config"
import http from "@/utils/request"

export  default {
        getTokenByPass:{
            url: `${config.API_URL}/login/token`,
            name: "通过账户和密码登录获取TOKEN",
            post: async function(data={}){
                return await http.post(this.url, data);
            }
        },
        getTokenByPhone:{
            url: `${config.API_URL}/login/token`,
            name: "通过手机登录获取TOKEN",
            post: async function(data={}){
                return await http.post(this.url, data);
            }
        }
}