import config from "@/config"
import http from "@/utils/request"

export default {
	//节假日
	holiday: {
		list: {
			url: `${config.API_URL}/config_holiday/list`,
			name: "获取节假日信息列表",
			get: async function() {
				return await http.get(this.url);
			}
		},
		update: {
			url: `${config.API_URL}/config_holiday/save`,
			name: "更新/添加节假日信息",
			post: async function(param) {
				return await http.post(this.url, param);
			}
		},
		del: {
			url: `${config.API_URL}/config_holiday/del`,
			name: "删除节假日信息",
			post: async function(param) {
				return await http.post(this.url, param);
			}
		}
	},
	//发票参数
	invoice: {
		list: {
			url: `${config.API_URL}/config_invoice_content/list`,
			name: "获取发票参数信息列表",
			get: async function() {
				return await http.get(this.url);
			}
		},
		update: {
			url: `${config.API_URL}/config_invoice_content/save`,
			name: "更新/添加发票参数信息列表",
			post: async function(param) {
				return await http.post(this.url, param);
			}
		},
	},
	//微信通知模板消息
	wechat: {
		list: {
			url: `${config.API_URL}/wechat_message/list`,
			name: "获取微信参数信息列表",
			get: async function() {
				return await http.get(this.url);
			}
		},
		update: {
			url: `${config.API_URL}/wechat_message/save`,
			name: "更新/添加微信参数信息列表",
			post: async function(param) {
				return await http.post(this.url, param);
			}
		},
	},
}
